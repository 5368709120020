export const ALERT_DANGER = "ALERT_DANGER"
export const ALERT_SUCCESS = "ALERT_SUCCESS"
export const ALERT_INFO = "ALERT_INFO"

export const SEARCH_CHAR_INTERVAL = 30
export const RESULTS_CHAR_INTERVAL = 75

export const REPLY_AREA_HEIGHT_ENGINEER = 80
export const REPLY_AREA_HEIGHT_CUSTOMER = 75
export const SEARCH_SUGGESTION_LIMIT = 10

// Error codes
export const ERROR_CREATE_ISSUE = 1002
export const ERROR_FETCH_ISSUE = 1003
export const ERROR_UPDATE_ISSUE = 1004
export const ERROR_ANSWER_ISSUE = 1005
export const ERROR_FETCH_CHANNEL = 1007
export const ERROR_SEND_NOTIFICATION = 1009
export const ERROR_FETCH_ISSUES = 1010
export const ERROR_SEND_MESSAGE = 1011
export const ERROR_EDIT_MESSAGE = 1012
export const ERROR_DELETE_MESSAGE = 1013
export const ERROR_LIST_NOTICES = 1014

export const ERROR_FETCH_USERS = 2001
export const ERROR_UPDATE_INFO = 2002
export const ERROR_UPLOAD_AVATAR = 2003
export const ERROR_CHANGE_PASSWORD = 2004
export const ERROR_RESET_PASSWORD = 2005
export const ERROR_FETCH_USERINFO = 2006
export const ERROR_PANIC_INFO = 2007
export const ERROR_REMOVE_IMAGE = 2008

export const ERROR_UPLOAD_FILE = 3001

export const ERROR_SIGN_UP = 4001
export const ERROR_LOG_IN = 4002

export const ERROR_SEND_INVITE = 5001

export const ERROR_OFFLINE = 6001

export const ERROR_FETCH_CHAT_TOKEN = 7001

export const TWILIO_STATE_CONNECTED = "connected"
export const TWILIO_STATE_DISCONNECTING = "disconnecting"
export const TWILIO_STATE_DISCONNECTED = "disconnected"

export const SEARCH_TYPE_ISSUE = "issues"
export const SEARCH_TYPE_TITLE = "title"
export const SEARCH_TYPE_ALL = "all"

export const TWILIO_CHANNEL_NOTLOADED = "TWILIO_CHANNEL_NOTLOADED"
export const TWILIO_CHANNEL_LOADING = "TWILIO_CHANNEL_LOADING"
export const TWILIO_CHANNEL_LOADED = "TWILIO_CHANNEL_LOADED"

export const THUMBNAIL_STATUS_LOADING = "THUMBNAIL_STATUS_LOADING"
export const THUMBNAIL_STATUS_LOADED = "THUMBNAIL_STATUS_LOADED"
export const THUMBNAIL_STATUS_NOTFOUND = "THUMBNAIL_STATUS_NOTFOUND"

export const ISSUE_STATUS_OPEN = "ISSUE_STATUS_OPEN"
export const ISSUE_STATUS_ANSWERED = "ISSUE_STATUS_ANSWERED"
export const ISSUE_STATUS_ARCHIVED = "ISSUE_STATUS_ARCHIVED"
export const ISSUE_STATUS_WAITING_FOR_RESPONSE =
  "ISSUE_STATUS_WAITING_FOR_RESPONSE"

export const ISSUE_ANSWERED_TRUE = "answered"
export const ISSUE_ANSWERED_FALSE = "unanswered"
export const PANIC_ISSUE = "Panic"
export const NORMAL_ISSUE = "Normal"

export const NOTIFICATIONS_ENABLED = "NOTIFICATIONS_ENABLED"
export const NOTIFICATIONS_DISABLED = "NOTIFICATIONS_DISABLED"
export const NOTIFICATIONS_UNKNOWN = "NOTIFICATIONS_UNKNOWN"

export const TEST_COMPANY_NAME = "Gringotts Inc."
export const TEST_COMPANY_ACC_ID = 1

export const PRICING_PLAN_STANDARD = "STANDARD"
export const PRICING_PLAN_ENTERPRISE = "ENTERPRISE"
export const PRICING_PLAN_ENTERPRISE_LITE = "ENTERPRISE-LITE"
export const PRICING_PLAN_ENTERPRISE_PLUS = "ENTERPRISE-PLUS"

export const INVITE_USER_TYPE_USER = "user"
export const INVITE_USER_TYPE_ENGINEER = "engineer"
export const INVITE_USER_TYPE_OWNER = "owner"
export const INVITE_USER_TYPE_ENGINEER_ADMIN = "admin"

export const ACCOUNT_TYPE_SUBSCRIBED = "SUBSCRIBED"
export const ACCOUNT_TYPE_CUSTOM = "CUSTOM"

export const PAYMENT_FAILURE_CUSTOMER_ADMIN_MESSAGE =
  "A recent charge failed to go through. Please update your payment method."

export const PRICING_PAGE_URL = "min.io/pricing"
export const CONTACT_SUCCESS_MSG =
  "We have received your request. We will be in touch with you soon!"
export const PRIVACY_POLICY_URL = "/privacy-policy"
export const TERMS_AND_CONDITIONS_URL_STANDARD =
  "/terms-and-conditions/standard"
export const TERMS_AND_CONDITIONS_URL_ENTERPRISE =
  "/terms-and-conditions/enterprise"
export const TERMS_AND_CONDITIONS_URL_TRIAL = "/terms-and-conditions/trial"
export const SORT_ORDER = {
  ASC: "ASC",
  DESC: "DESC"
}
export const SELF_REPORT_FREQUENCY_IN_DAYS = 90
export const SELF_REPORT_DUE_MSG = "Usage capacity reporting is due!"

export const MEDIA_BREAKPOINTS = {
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl"
}
/*As in tailwind.config.js Any update there must be updated here..*/
export const MEDIA_QUERIES = {
  [MEDIA_BREAKPOINTS.SM]: "(min-width: 640px)",
  [MEDIA_BREAKPOINTS.MD]: "(min-width: 768px)",
  [MEDIA_BREAKPOINTS.LG]: "(min-width: 1024px)",
  [MEDIA_BREAKPOINTS.XL]: "(min-width: 1280px)"
}

export const SUBSCRIPTION_PROVIDERS = {
  STRIPE: "STRIPE",
  CHARGEBEE: "CHARGEBEE"
}

export const CURRENCY_LIST = {
  USD: "USD",
  EUR: "EUR"
}

export const DEPLOY_ENV = {
  DEV: "development",
  STAGING: "staging",
  PROD: "production"
}

export const LEGAL_DOC_FILE_TYPE = "legal_doc"
export const HEALTH_REPORT_FILE_TYPE = "health_report"
export const CLUSTER_PROFILE_FILE_TYPE = "profile"
export const CLUSTER_PERF_FILE_TYPE = "perf_result"
export const CLUSTER_INSPECT_FILE_TYPE = "inspect"

export const ROUTE_PATHS = {
  LOGIN: "/",
  HEALTH: "/health",
  ISSUES: "/issues",
  LICENSE: "/license",
  USERS: "/users",
  PANIC: "/panic",
  CUSTOMERS: "/customers",
  SUBSCRIPTION: "/subscription",
  JOIN_ORGANIZATION: "/join-organization",
  ADD_ORGANIZATION: "/add-organization",
  CREATE_ACCOUNT: "/create-account",
  REGISTER_CLUSTER: "/cluster/register",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
  ALERTS: "/alerts",
  UPGRADE_SUBSCRIPTION: "/upgrade-subscription",
  UPGRADE_CONFIRMATION: "/upgrade-subscription-confirmation",
  END_SUBSCRIPTION: "/end-subscription",
  USERS_WITHOUT_MEMBERSHIP: "/users-without-membership",
  FILES: "/files",
  TODOS: "/todos",
  ENGINEER_ACTIVITY_V2: "/activity/v2",
  ENGINEER_ACTIVITY: "/activity",
  PURGED_LIST: "/purgeview"
}

// Server-sent events
export const EVENT_ISSUE_ANSWER_STATE_CHANGED = "IssueAnswerstateChanged"
export const EVENT_PANIC_ISSUE_CREATED = "PanicIssueCreated"
export const EVENT_ISSUE_CREATED = "IssueCreated"
export const EVENT_CHAT_MESSAGE_CREATED = "ChatMessageCreated"
export const EVENT_ORGANIZATION_UPDATED = "OrganizationUpdated"

// Emoticons
export const EMOTICONS = {
  ":|": "😐",
  "=)": "😀",
  "=-)": "😀",
  ";)": "😉",
  ":>": "😆",
  ":->": "😆",
  ":o": "😮",
  ":-o": "😮",
  ">:(": "😠",
  ">:-(": "😠",
  ":)": "🙂",
  "(:": "🙂",
  ":-)": "🙂",
  ":(": "😞",
  "):": "😞",
  ":-(": "😞",
  ":-/": "😕",
  ":p": "😛",
  ":-p": "😛",
  ":-b": "😛",
  ";p": "😜",
  ";-p": "😜",
  ";b": "😜",
  ";-b": "😜",
  ":/": "😕"
}

export const INACTIVE_USER_TEXT = "Deactivated User"
export const OPEN_ISSUES_TAB_PERSIST_KEY = "openIssueTabs"
export const ISSUES_FILTER_SORT_PERSIST_KEY = "issues_filter_sort"

export const PANIC_TITLES = {
  main: "Please use this feature only for severe outages.",
  sub: "Panic will alert every MinIO engineer worldwide.",
  confirm: "Confirm to send panic alerts to all MinIO engineers."
}

export const SIDEBAR_STATE_MINI_STORAGE_KEY = "isMiniSidebarMenu"
export const TABS_CONTEXT_MENU_ID = "tab-close-context-menu"
export const ISSUE_TABS_CLOSE_THIS = "close_this"
export const ISSUE_TABS_CLOSE_OTHERS = "close_others"
export const ISSUE_TABS_CLOSE_ALL = "close_all"

export const EXEMPT_CATEGORY_LEVEL = 1
export const ISSUE_TITLE_MAX_LENGTH = 100

export const ASSIGNMENT_STATUS_UNASSIGNED = "unassigned"
export const ASSIGNMENT_STATUS_ASSIGNED = "assigned"
export const ASSIGNMENT_USER_ID = "assignee"
export const I_AM_FOLLOWING_ISSUES = "following"
export const I_AM_PARTICIPATING = "participating"
export const MY_UNREAD_MSG_ISSUES = "unread"
export const ONE_HOUR = 60 * 60 * 1000

export const ENG_MAX_ISSUE_TABS_LIMIT = 50
