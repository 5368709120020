import {
  SET_ALERT,
  CLEAR_ALERT,
  ADD_TYPING_MEMBER,
  REMOVE_TYPING_MEMBER,
  RESET_TYPING_MEMBERS,
  SET_ENGINEERS,
  RESET_ENGINEERS,
  SET_CONVERSATIONS,
  RESET_CONVERSATIONS,
  ADD_CONVERSATION,
  UPDATE_CONVERSATION,
  SET_CONVERSATION_LAST_UPDATED_AT,
  SET_ACCOUNTS,
  SHOW_SEARCH_MENU,
  HIDE_SEARCH_MENU,
  SET_SEARCH_MESSAGE_INDEX,
  RESET_SEARCH_MESSAGE_INDEX,
  SHOW_TEXT_UPLOAD_MODAL,
  HIDE_TEXT_UPLOAD_MODAL,
  BEGIN_UPLOAD,
  COMPLETE_UPLOAD,
  CANCEL_UPLOAD,
  SHOW_ABORT_UPLOAD,
  HIDE_ABORT_UPLOAD,
  SET_UPLOAD_PERCENTAGE,
  SET_UNREAD_COUNT,
  SET_USER_INFO,
  SET_USERS,
  SET_SEARCH_PARAMS,
  RESET_SEARCH_PARAMS,
  SHOW_ISSUE_LOADER,
  HIDE_ISSUE_LOADER,
  RESET_APP,
  SET_USER_THUMBNAIL,
  SET_SELECTED_ACCOUNT,
  SET_ISSUE_FILTER,
  RESET_SELECTED_ACCOUNT,
  SHOW_MOBILE_SIDEBAR,
  HIDE_MOBILE_SIDEBAR,
  SHOW_MOBILE_SEARCH,
  HIDE_MOBILE_SEARCH,
  PLAY_NOTIFICATION_SOUND,
  STOP_NOTIFICATION_SOUND,
  SET_TWILIO_STATE,
  CLEAR_ISSUE,
  SET_ISSUE,
  UPDATE_ISSUE,
  RESET_USER_THUMBNAILS,
  SET_NOTIFICATION_STATUS,
  SET_ONLINE_USERS,
  SHOW_VIRTUAL_TOUR,
  HIDE_VIRTUAL_TOUR,
  SET_ACCOUNTS_SEARCH_TEXT,
  SET_SUBSCRIPTION_INFO,
  SET_ENGINEERS_SEARCH_TEXT,
  SET_USERS_SEARCH_TEXT,
  SET_ACCOUNT_DETAILS_SEARCH_TEXT,
  SET_ALERTS_SUMMARY,
  SET_REGISTERED_CLUSTERS,
  SET_CLUSTER_ANALYSIS_RESULT,
  SET_APP_ENV,
  SET_FEEDBACK_ACCOUNT_FILTER,
  SET_LICENSES_ACCOUNT_FILTER,
  SET_NON_MEMBER_USER_SEARCH_TEXT,
  SHOW_ASSIGN_MODAL,
  HIDE_ASSIGN_MODAL,
  SET_FILE_LIST_SEARCH_TEXT,
  SET_TODO_SUMMARY,
  OPEN_ISSUE_TAB,
  CLOSE_ISSUE_TAB,
  LOAD_ISSUE_INFO,
  RESTORE_OPEN_TABS,
  UPDATE_OPEN_ISSUE_TAB,
  RESET_ACTIVE_TABS
} from "./actions"

import {
  TWILIO_STATE_DISCONNECTED,
  NOTIFICATIONS_UNKNOWN,
  ENG_MAX_ISSUE_TABS_LIMIT
} from "./constants"

const setLastUpdatedAt = (conversations, conversation, lastUpdatedAt) => {
  return conversations.map(c => {
    if (c.issue.issue_number === conversation.issue.issue_number) {
      return {
        ...c,
        lastUpdatedAt: lastUpdatedAt
      }
    } else {
      return c
    }
  })
}

const setUnreadCount = (conversations, conversation, count) => {
  return conversations.map(c => {
    if (c.channelId === conversation.channelId) {
      return {
        ...c,
        unreadMessageCount: count
      }
    } else {
      return c
    }
  })
}

const orderConversations = (conversations, conversation) => {
  const unanswered = conversations.filter(
    c =>
      c.issue &&
      c.issue.issue_answer_state &&
      c.issue.issue_answer_state.state === false
  )
  const answered = conversations.filter(
    c =>
      c.issue &&
      c.issue.issue_answer_state &&
      c.issue.issue_answer_state.state === true
  )
  return [...unanswered, conversation, ...answered]
}

const updateIssue = (prevIssue, newIssue) => {
  // Update only when the issue is same
  if (prevIssue && prevIssue.issue_number === newIssue.issue_number) {
    return newIssue
  } else {
    return prevIssue
  }
}

const updateConversation = (conversations, conversation) => {
  return conversations.map(c => {
    if (c.issue.issue_number === conversation.issue.issue_number) {
      return conversation
    } else {
      return c
    }
  })
}

const initialState = {
  userInfo: {},
  engineers: [],
  onlineUserIds: [],
  typingMembers: new Set(),
  showTextUploadModal: false,
  showAbortUploadModal: false,
  upload: [],
  showSearchMenu: false,
  alert: {},
  conversations: [],
  accounts: { list: [], isLoading: false },
  users: [],
  userThumbnails: {},
  showIssueLoader: false,
  selectedAccountId: 0,
  showMobileSidebar: false,
  showMobileSearch: false,
  showVirtualTour: false,
  notificationsEnabled: false,
  playNotificationSound: false,
  twilioState: TWILIO_STATE_DISCONNECTED,
  issue: undefined,
  issueFilter: {},
  notificationsStatus: NOTIFICATIONS_UNKNOWN,
  accountsSearchText: "",
  subscriptionInfo: {},
  registeredClusters: [],
  clusterAnalysisResults: {},
  alertsSummary: {},
  nonMemberUsersSearchText: "",
  fileListSearchText: "",
  todoSummary: { engineer: 0, customer: 0 },
  issueTabsOpen: []
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_APP:
      return {
        ...initialState
      }
    case SET_ALERT:
      return {
        ...state,
        alert: action.alert
      }
    case CLEAR_ALERT:
      return {
        ...state,
        alert: {}
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo
      }
    case ADD_TYPING_MEMBER:
      const typingMembers = new Set([...state.typingMembers])
      typingMembers.add(action.member.identity)
      return {
        ...state,
        typingMembers: typingMembers
      }
    case REMOVE_TYPING_MEMBER: {
      let typingMembers = new Set([...state.typingMembers])
      typingMembers.delete(action.member.identity)
      return {
        ...state,
        typingMembers: typingMembers
      }
    }
    case RESET_TYPING_MEMBERS: {
      return {
        ...state,
        typingMembers: new Set()
      }
    }
    case SHOW_SEARCH_MENU:
      return {
        ...state,
        showSearchMenu: true
      }
    case HIDE_SEARCH_MENU:
      return {
        ...state,
        showSearchMenu: false
      }
    case SET_SEARCH_MESSAGE_INDEX:
      return {
        ...state,
        searchResultIndex: action.index
      }
    case RESET_SEARCH_MESSAGE_INDEX:
      return {
        ...state,
        searchResultIndex: undefined
      }
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        search: {
          query: action.query,
          issueNumber: action.issueNumber,
          accountId: action.accountId
        }
      }
    case RESET_SEARCH_PARAMS:
      return {
        ...state,
        search: {}
      }
    case SHOW_TEXT_UPLOAD_MODAL:
      return {
        ...state,
        showTextUploadModal: true
      }
    case HIDE_TEXT_UPLOAD_MODAL:
      return {
        ...state,
        showTextUploadModal: false
      }
    case BEGIN_UPLOAD:
      const newUploadList = [
        ...state.upload,
        {
          isCancelled: false,
          isUploading: true,
          name: action.fileName,
          fileId: action.fileId,
          uploadFrom: action.uploadFrom,
          percentage: 0
        }
      ]
      return {
        ...state,
        upload: newUploadList
      }
    case COMPLETE_UPLOAD:
      const pendingList = state.upload.filter(
        item => item.fileId !== action.fileId
      )
      return {
        ...state,
        upload: pendingList
      }
    case CANCEL_UPLOAD:
      const cancelledList = state.upload.map(item => {
        if (item.fileId !== action.fileId) {
          return item
        }
        return {
          ...item,
          isUploading: false,
          isCancelled: true
        }
      })
      return {
        ...state,
        upload: cancelledList
      }
    case SET_UPLOAD_PERCENTAGE:
      const updatedList = state.upload.map(item => {
        if (item.fileId !== action.fileId) {
          return item
        }
        return {
          ...item,
          percentage: action.percentage
        }
      })

      return {
        ...state,
        upload: updatedList
      }
    case SHOW_ABORT_UPLOAD:
      return {
        ...state,
        showAbortUploadModal: true
      }
    case HIDE_ABORT_UPLOAD:
      return {
        ...state,
        showAbortUploadModal: false
      }
    case SET_ENGINEERS:
      return {
        ...state,
        engineers: action.engineers
      }
    case RESET_ENGINEERS:
      return {
        ...state,
        engineers: []
      }
    case SET_CONVERSATIONS:
      const { autoRefresh, conversations: newConversations } = action
      const conversations = autoRefresh
        ? [...newConversations] // in a refresh, all loaded pages are flushed at once
        : [...state.conversations, ...newConversations] // paged loading.

      return {
        ...state,
        conversations: conversations
      }
    case RESET_CONVERSATIONS:
      return {
        ...state,
        conversations: []
      }
    case ADD_CONVERSATION:
      return {
        ...state,
        conversations: orderConversations(
          state.conversations,
          action.conversation
        )
      }
    case UPDATE_CONVERSATION:
      return {
        ...state,
        conversations: updateConversation(
          state.conversations,
          action.conversation
        )
      }
    case SET_CONVERSATION_LAST_UPDATED_AT:
      return {
        ...state,
        conversations: setLastUpdatedAt(
          state.conversations,
          action.conversation,
          action.lastUpdatedAt
        )
      }
    case SET_UNREAD_COUNT:
      return {
        ...state,
        conversations: setUnreadCount(
          state.conversations,
          action.conversation,
          action.count
        )
      }
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: { list: action.accounts, isLoading: action.isLoading }
      }
    case SET_USERS:
      return {
        ...state,
        users: action.users
      }
    case SET_USER_THUMBNAIL: {
      return {
        ...state,
        userThumbnails: {
          ...state.userThumbnails,
          [action.userId]: { status: action.status, url: action.url }
        }
      }
    }
    case RESET_USER_THUMBNAILS: {
      return {
        ...state,
        userThumbnails: {}
      }
    }
    case SHOW_ISSUE_LOADER:
      return {
        ...state,
        showIssueLoader: true
      }
    case HIDE_ISSUE_LOADER:
      return {
        ...state,
        showIssueLoader: false
      }
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccountId: action.accountId
      }
    case RESET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccountId: 0
      }
    case SET_ISSUE_FILTER:
      return {
        ...state,
        issueFilter: action.filterValue
      }
    case SHOW_MOBILE_SIDEBAR:
      return {
        ...state,
        showMobileSidebar: true
      }

    case HIDE_MOBILE_SIDEBAR:
      return {
        ...state,
        showMobileSidebar: false
      }
    case SHOW_MOBILE_SEARCH:
      return {
        ...state,
        showMobileSearch: true
      }
    case HIDE_MOBILE_SEARCH:
      return {
        ...state,
        showMobileSearch: false
      }
    case SHOW_VIRTUAL_TOUR:
      return {
        ...state,
        showVirtualTour: true
      }
    case HIDE_VIRTUAL_TOUR:
      return {
        ...state,
        showVirtualTour: false
      }
    case PLAY_NOTIFICATION_SOUND:
      return {
        ...state,
        playNotificationSound: true
      }
    case STOP_NOTIFICATION_SOUND:
      return {
        ...state,
        playNotificationSound: false
      }
    case SET_TWILIO_STATE:
      return {
        ...state,
        twilioState: action.twilioState
      }
    case SET_ISSUE:
      return {
        ...state,
        issue: action.issue
      }
    case OPEN_ISSUE_TAB:
      const issueTabs = state.issueTabsOpen
        .slice(0, ENG_MAX_ISSUE_TABS_LIMIT - 1)
        .reduce((acc, ti) => {
          if (ti.issueNumber !== action.issueNumber) {
            acc.push({
              ...ti,
              isActive: false
            })
          }
          return acc
        }, [])
      return {
        ...state,
        issueTabsOpen: [
          {
            isActive: true,
            issueNumber: action.issueNumber,
            issue: action.issue
          }
        ].concat(issueTabs)
      }
    case RESET_ACTIVE_TABS:
      const openTabsList = state.issueTabsOpen.map(ti => {
        return {
          ...ti,
          isActive: false
        }
      })
      return {
        ...state,
        issueTabsOpen: openTabsList
      }
    case UPDATE_OPEN_ISSUE_TAB:
      const openIssueTabs = state.issueTabsOpen.map(oIssue => {
        if (oIssue.issueNumber === action.issueNumber) {
          return {
            issueNumber: action.issueNumber,
            isActive: action.isActive || oIssue.isActive,
            issue: { ...action.issue }
          }
        }
        return oIssue
      })

      return {
        ...state,
        issueTabsOpen: openIssueTabs
      }
    case CLOSE_ISSUE_TAB:
      const restOpenTabs = state.issueTabsOpen.filter(
        ti => ti.issueNumber !== action.issueNumber
      )

      return {
        ...state,
        issueTabsOpen: restOpenTabs
      }
    case LOAD_ISSUE_INFO:
      const loadedOpenTabs = state.issueTabsOpen.reduce((acc, ti) => {
        if (ti.issueNumber === action.issueNumber) {
          acc.push({
            ...ti,
            issue: action.issue
          })
        } else {
          acc.push(ti)
        }

        return acc
      }, [])

      return {
        ...state,
        issueTabsOpen: loadedOpenTabs
      }

    case RESTORE_OPEN_TABS:
      return {
        ...state,
        issueTabsOpen: action.tabsToRestore
      }

    case CLEAR_ISSUE:
      return {
        ...state,
        issue: undefined
      }
    case UPDATE_ISSUE:
      return {
        ...state,
        issue: updateIssue(state.issue, action.issue)
      }
    case SET_NOTIFICATION_STATUS:
      return {
        ...state,
        notificationsStatus: action.notificationsStatus
      }
    case SET_ONLINE_USERS:
      return {
        ...state,
        onlineUserIds: action.onlineUserIds
      }
    case SET_SUBSCRIPTION_INFO:
      return {
        ...state,
        subscriptionInfo: action.subscriptionInfo
      }
    case SET_ACCOUNTS_SEARCH_TEXT:
      return {
        ...state,
        accountsSearchText: action.accountsSearchText
      }
    case SET_NON_MEMBER_USER_SEARCH_TEXT:
      return {
        ...state,
        nonMemberUsersSearchText: action.nonMemberUsersSearchText
      }
    case SET_ACCOUNT_DETAILS_SEARCH_TEXT:
      return {
        ...state,
        accountDetailsSearchText: action.accountDetailsSearchText
      }
    case SET_ENGINEERS_SEARCH_TEXT:
      return {
        ...state,
        engineersSearchText: action.engineersSearchText
      }
    case SET_USERS_SEARCH_TEXT:
      return {
        ...state,
        usersSearchText: action.usersSearchText
      }
    case SET_REGISTERED_CLUSTERS:
      return {
        ...state,
        registeredClusters: action.registeredClusters
      }
    case SET_CLUSTER_ANALYSIS_RESULT:
      return {
        ...state,
        clusterAnalysisResults: {
          ...state.clusterAnalysisResults,
          [action.clusterId]: action.analysisResult
        }
      }
    case SET_APP_ENV:
      return {
        ...state,
        appEnv: action.appEnv
      }

    case SET_ALERTS_SUMMARY:
      return {
        ...state,
        alertsSummary: action.alertsSummary
      }
    case SET_FEEDBACK_ACCOUNT_FILTER:
      return {
        ...state,
        feedbackAccountFilter: action.accountId
      }
    case SET_LICENSES_ACCOUNT_FILTER:
      return {
        ...state,
        licensesAccountFilter: action.accountId
      }
    case SET_FILE_LIST_SEARCH_TEXT:
      return {
        ...state,
        fileListSearchText: action.fileListSearchText
      }
    case SHOW_ASSIGN_MODAL:
      return {
        ...state,
        assignModalOpenFor: action.assignModalOpenFor
      }
    case HIDE_ASSIGN_MODAL:
      return {
        ...state,
        assignModalOpenFor: null
      }
    case SET_TODO_SUMMARY:
      return {
        ...state,
        todoSummary: action.todoSummary
      }
    default:
      return state
  }
}

export default appReducer
